(function ($) {

  class HPImpacts {

    constructor(el) {
      const children = Array.from(el.querySelectorAll(".post__preview"));

      this.view = {
        el: el,
        lastChild: children.pop(),
        title: el.querySelector(".impacts__title"),
        container: el.querySelector(".impacts__content")
      }

      this.handleResize();
      this.initHandlers();
    }

    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async maybeResize(limit,interval,positive = true) {
      return new Promise(async resolve => {
        const condition = (positive ? () => {
            return this.view.lastChild.getBoundingClientRect().x > this.view.contOffset && buffer < limit;
          }
          : () => {
            return this.view.lastChild.getBoundingClientRect().x < this.view.contOffset && buffer > limit;
          })

        let buffer = 0;
        while (condition()) {
          const height = this.view.contRect.height;
          this.view.container.style.maxHeight = height + buffer + "px"; 
          await this.sleep(1);
          buffer += interval;
        }
        resolve();
      })
    }

    async handleResize() {

      //Use the title element to gauge the correct width for the container
      this.view.titleRect = this.view.title.getBoundingClientRect();
      this.view.contOffset = this.view.titleRect.width + this.view.titleRect.x;
      //Use the container element to gauge the current height
      this.view.contRect = this.view.container.getBoundingClientRect();
      

      //While the last card is less than halfway across the container, decrease the height of the container until the cards fill three columns
      //Could happen on resize up from tablet to desktop width
      await this.maybeResize(-2000,-10,false);

      //While the last card is hanging outside the container, increase the height of the container until it fits
      this.maybeResize(2000,10);

    }

    initHandlers() {
      this.resizeTimeout = undefined;
      //Run this routine again on window resize, since the dimensions of the container will have changed
      window.addEventListener("resize",() => {
        if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.handleResize.bind(this),500);
      })
    }
  }

  //Resize impacts block so cards never overflow horizontally
  $(".component-hp-impacts").each(function() {
    new HPImpacts(this);
  })

})(jQuery)